import React, { useState , useCallback} from 'react'
import { Navbar , NavDropdown , Nav} from 'react-bootstrap'
import { NavLink , useHistory} from 'react-router-dom'


const style = {
    display : 'flex',
    justifyContent: 'center',
    borderTop: 'solid 2px rgba(70,130,180,0.3)',
    color: 'rgba(70,130,180,1 )',
    weigth:'bolder',
    margin: 'auto',
    width:'85%',

}


const Footer = () => {

    return (
        <div style={style}>
            ernc@dgf.uchile.cl
        </div>
    )

}

export default Footer;