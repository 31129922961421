import React, { useState } from 'react'
import { Navbar , NavDropdown , Nav} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import logo from '../../imagenes/logo_min2.png'

const styles = {
    containerLink:{
      color: '#FFF',
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 'medium',
      width: '100%',
      gap: '5px',
    },
    link : {
      color:'#FFF',
    },
    navbar:{
      position: 'sticky',
      display:'flex',
      padding: '0rem 1rem',
      top:'0',
      color:'#FFF',
      zIndex:'9999',
      background: 'linear-gradient(142deg, rgba(70,130,180,1) 0%, rgba(70,130,180,0.65) 100%)',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '90%',
      margin:'auto',
    }

}


function NavBarra (){

    const [show, setShow] = useState({centrales:false,proyectos:false,potencial:false,DAA:false});




    const showDropdown = () => {
        setShow(!show);
    }
    const hideDropdown = () => {
        setShow(false);
    }

    return(
      <Navbar style={styles.navbar} >
        <img alt="logo" src={logo} height='100vh'/>
        <div style={styles.flex}>
          <Navbar.Brand style={styles.link} href="/">
            {' '}
            Explorador Hidroeléctrico
          </Navbar.Brand>
          <div style={styles.containerLink} >
              <NavLink  className="nav-link" style={styles.link} to='/' >Inicio</NavLink>
              <NavDropdown  title="Centrales"
                            id="basic-nav-dropdown"
                            show={show.centrales}
                            onMouseEnter={() => setShow({proyectos:false,potencial:false,centrales:true,DAA:false})}
                            onMouseLeave={() => setShow({...show,centrales:false})} >
                <NavLink  className="dropdown-item" style={styles.link} to='/centrales/' >Menu Centrales</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/centrales/resumen'>Resumen Centrales</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/centrales/mapa' > Mapa Centrales </NavLink>
              </NavDropdown>
              <NavDropdown   title="Proyectos"
                             id="basic-nav-dropdown"
                             show={show.proyectos}
                             onMouseEnter={() => setShow({proyectos:true,potencial:false,centrales:false,DAA:false})}
                             onMouseLeave={() => setShow({...show,proyectos:false})} >
                <NavLink  className="dropdown-item" style={styles.link} to='/proyectos/' >Menu Proyectos</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/proyectos/resumen'>Resumen Proyectos</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/proyectos/mapa' > Mapa Proyectos</NavLink>
              </NavDropdown>
              <NavDropdown   title="Potencial"
                             id="basic-nav-dropdown"
                             show={show.potencial}
                             onMouseEnter={() => setShow({proyectos:false,potencial:true,centrales:false,DAA:false})}
                             onMouseLeave={() => setShow({...show,potencial:false})} >
                <NavLink  className="dropdown-item" style={styles.link}  to='/potencial/'>Menu Potencial</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/potencial/pasada-resumen'>Resumen PCH Pasada</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/potencial/pasada-mapa' > Mapa PCH Pasada</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/potencial/costa-resumen'>Resumen PCB costa</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/potencial/costa-mapa' > Mapa PCB Costa</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/potencial/continente-resumen'>Resumen PCB Continente</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/potencial/continente-mapa' > Mapa PCB Continente</NavLink>
              </NavDropdown>
              <NavDropdown   title="DAA"
                             id="basic-nav-dropdown"
                             show={show.DAA}
                             onMouseEnter={() => setShow({proyectos:false,potencial:false,centrales:false,DAA:true})}
                             onMouseLeave={() => setShow({...show,DAA:false})} >
                <NavLink  className="dropdown-item" style={styles.link}  to='/DAA/'>Menu DAA</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/DAA/DAAC-resumen'>Resumen DAAC</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/DAA/DAAC-mapa' > Mapa DAAC</NavLink>
                <NavDropdown.Divider />
                <NavLink  className="dropdown-item" style={styles.link}  to='/DAA/DAANC-resumen'>Resumen DAANC</NavLink>
                <NavLink  className="dropdown-item" style={styles.link} to='/DAA/DAANC-mapa' > Mapa DAANC</NavLink>
              </NavDropdown>
          </div>

        </div>
      </Navbar>
    )

}

export default NavBarra;
