import React, { Component } from  'react'
import { BrowserRouter as Router, Switch ,  Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Nav from './components/etiquetas/Nav'
import Footer from './components/etiquetas/Footer'
import './sass/app.css'
import './App.scss'
import Highcharts from 'highcharts'
import GroupedCategories from 'highcharts-grouped-categories/grouped-categories'
import Exporting from 'highcharts-exporting'
import SeriesLabel from 'highcharts-series-label'
import Acces from 'highcharts-accessibility'
import HC_more from 'highcharts/highcharts-more'



GroupedCategories(Highcharts);



const Loader = x => Loadable({
  loading: () => 'Cargando...',
  loader: x
})

const Inicio = Loader( () => import('./Inicio'))

// centrales
const Centrales = Loader( () => import('./components/Centrales/Centrales'))

const CentralMapa = Loader( () => import('./components/Centrales/CentralMapa/CentralMapa'))
const CentralResumen = Loader( () => import('./components/Centrales/CentralResumen/CentralResumen'))

// proyectos
const ProyectosHidro = Loader( () => import('./components/ProyectosHidro/ProyectosHidro'))

const HidroMapa = Loader( () => import('./components/ProyectosHidro/HidroMapa/HidroMapa'))
const HidroResumen = Loader( () => import('./components/ProyectosHidro/HidroResumen/HidroResumen'))

// potencial
const PotencialHidro = Loader( () => import('./components/PotencialHidro'))

const CostaMapa = Loader( () => import('./components/PotencialCosta/CostaMapa/CostaMapa'))
const CostaResumen = Loader( () => import('./components/PotencialCosta/CostaResumen/CostaResumen'))

const ContinenteMapa = Loader(() => import('./components/PotencialContinente/ContinenteMapa/ContinenteMapa'))
const ContinenteResumen = Loader( () => import('./components/PotencialContinente/ContinenteResumen/ContinenteResumen'))

//Pasada
const PasadaMapa = Loader(() => import('./components/PotencialPasada/PasadaMapa/PasadaMapa'))
const PasadaResumen = Loader(() => import('./components/PotencialPasada/PasadaResumen/PasadaResumen'))

//DAA
const DAA = Loader( () => import('./components/DAA/DAA'))

const DAACMapa = Loader(() => import('./components/DAA/DAAC/DAACMapa/DAACMapa'))
const DAACResumen = Loader(() => import('./components/DAA/DAAC/DAACResumen/DAACResumen'))

const DAANCMapa = Loader(() => import('./components/DAA/DAANC/DAANCMap/DAANCMapa'))
const DAANCResumen = Loader(() => import('./components/DAA/DAANC/DAANCResumen/DAANCResumen'))

//Mediciones
const MedicionesMapa = Loader(() => import('./components/Mediciones/MedicionesMapa/Mapa'))
const MedicionesResumen = Loader(() => import('./components/Mediciones/MedicionesResumen/MedicionesResumen'))

//Canales
const CanalesMapa = Loader(() => import('./components/Canales/CanalesMapa/Mapa'))
const CanalesResumen = Loader(() => import('./components/Canales/CanalesResumen/CanalesResumen'))
const MejorasCanalesMapa = Loader(()=> import('./components/MejorasCanales/MejorasMapa/Mapa'))
const MejorasCanalesResumen = Loader(() => import('./components/MejorasCanales/MejorasResumen/MejorasResumen'))


const App = () =>  {


    return (
      <div className='App'>
          <Router>
            <Switch>
              <Route exact path="/" component={Inicio}/>

              <Route exact path="/centrales" component={Centrales}/>
              <Route exact path="/centrales/resumen" component={CentralResumen}/>
              <Route exact path="/centrales/mapa" component={CentralMapa}/>

              <Route exact path="/proyectos" component={ProyectosHidro}/>
              <Route exact path="/proyectos/mapa" component={HidroMapa}/>
              <Route exact path="/proyectos/resumen" component={HidroResumen}/>

              <Route exact path="/potencial" component={PotencialHidro}/>

              <Route exact path="/potencial/costa-mapa" component={CostaMapa}/>
              <Route exact path="/potencial/costa-resumen" component={CostaResumen}/>

              <Route exact path="/potencial/continente-mapa" component={ContinenteMapa}/>
              <Route exact path="/potencial/continente-resumen" component={ContinenteResumen}/>

              <Route exact path="/potencial/pasada-mapa" component={PasadaMapa}/>
              <Route exact path="/potencial/pasada-resumen" component={PasadaResumen}/>

              <Route exact path="/DAA" component={DAA}/>

              <Route exact path="/DAA/DAAC-mapa" component={DAACMapa}/>
              <Route exact path="/DAA/DAAC-resumen" component={DAACResumen}/>

              <Route exact path="/DAA/DAANC-resumen" component={DAANCResumen}/>
              <Route exact path="/DAA/DAANC-mapa" component={DAANCMapa}/>

              <Route exact path="/mediciones/mapa" component={MedicionesMapa}/>
              <Route exact path="/mediciones/resumen" component={MedicionesResumen}/>

              <Route exact path="/canales/mapa" component={CanalesMapa}/>
              <Route exact path="/canales/resumen" component={CanalesResumen}/>
              <Route exact path="/mejorascanales/mapa" component={MejorasCanalesMapa}/>
              <Route exact path="/mejorascanales/resumen" component={MejorasCanalesResumen}/>


            </Switch>
          </Router>
      </div>
    )
}


export default App;
