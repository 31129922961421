import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import asyncMiddleware from './async-middleware'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { reducer as formReducer} from 'redux-form'
import './index.css';
import * as reducers from './reducers'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'




const combineR = combineReducers({
  ...reducers,
  form: formReducer,
})


const store = createStore(combineR , applyMiddleware(thunk , asyncMiddleware)) //pasarle el middleware que convierte thunk




ReactDOM.render(
    <Provider store={store}>
      <App/>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
